import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  campaignObjectifs,
  ageFacebookInstagram,
  regions,
  cta,
} from "../constants/campaignConstant";

////////////

import {
  Button,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
//////

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyle = makeStyles((theme) => ({
  root: {
    background: "white",
    // borderRadius: var(--radius);
    textAlign: "center",
    // boxShadow: var(--light - shadow);
    // transition: var(--transition)
    width: "100vw",
    maxWidth: "40rem",
    minHeight: 400,
    display: "grid",
  },
  button: {
    width: "150px",
    height: "50px",
    marginRight: 10,
    marginBottom: 10,
  },
  ctaContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  sectionTitle: {
    padding: "30px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.offWhite,
    margin: "0px 0px 2rem 0px",
    borderRadius: "10px 10px 0px 0px",
  },
  formGroup: {
    margin: "1rem 1.5rem",
  },
}));

////
function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

function Campagne(
  {
    // data: {
    //   allAirtable: { nodes: lesMarques },
    // },
  }
) {
  const classes = useStyle();
  const campaignObjectifData = [...campaignObjectifs];
  const [indexObjectif, setIndexObjectif] = useState(0);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  let object = {
    name: "",
    reference: "",
    campaign_type: "",
    budget: "",
    adName: "",
    objectif: "",
    start_date: new Date(),
    end_date: null,
    gender: "Homme/Femme",

    age_min: ageFacebookInstagram[5],
    age_max: ageFacebookInstagram[52],
    region: [regions[0]],
    remarque: "",
    creative_type: "",
    creative_link: "",
    creative_destination: "",
    creative_text: "",
    creative_file: null,
    creative_cta: "",
    creative_title: "",
    creative_sender: "",
    creative_description: "",
    creative_displayed_link: "",
    date_entree: new Date(),
  };

  const location = useLocation();

  const [globalState, setGlobalState] = useState(object);
  const [airtableInfo, setAirtableInfo] = useState(null);
  const [step, setStep] = useState(0);
  const [alert, setAlert] = useState(null);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  function alertBudgetRestant(Budget) {
    if (
      airtableInfo.MontantTotalRestant &&
      Budget - airtableInfo.MontantTotalRestant > 0
    ) {
      setAlert(
        <Alert severity="warning">
          Le montant de la commande dépasse le budget restant,Votre budget
          restant est de : {airtableInfo.MontantTotalRestant} DA
        </Alert>
      );
    } else {
      setAlert(null);
    }
  }

  let [budgetRestant, setBudgetRestant] = useState();

  // function substractBudgetRestant(network, budget_restant, budget) {
  //   budgetRestant = { ...airtableInfo.budget_restant };
  //   network = network.toLowerCase();
  //   setBudgetRestant({ ...budgetRestant, [network]: budget_restant - budget });
  // }

  const handelChange = (e) => {
    console.log(e);

    if (e.target.name === "creative_file") {
      let copy = { ...globalState };
      copy.creative_file = e.target.files[0];
      console.log(e.target.files[0]);
      console.log(copy);
      setGlobalState(copy);
    } else if (e.target.name === "budget") {
      setGlobalState({ ...globalState, [e.target.name]: e.target.value });

      // substractBudgetRestant(
      //   globalState.campaign_type,
      //   airtableInfo.budget_restant[globalState.campaign_type],
      //   e.target.value
      // );

      setBudgetRestant(airtableInfo.budget_restant.total - e.target.value);

      alertBudgetRestant(e.target.value);
    } else {
      setGlobalState({ ...globalState, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    setLoading(true);
    let query = parseQuery(location.search);
    if (query.client && query.ref) {
      if (sessionStorage.getItem(`Campaign_${query.ref}`)) {
        let session = JSON.parse(
          sessionStorage.getItem(`Campaign_${query.ref}`)
        );
        console.log(sessionStorage.getItem(`Campaign_${query.ref}`));

        let copy = { ...globalState };
        copy.name = session.name;
        copy.reference = session.reference;
        copy.adName = session.adName;
        copy.age_max = session.age_max;
        copy.age_min = session.age_min;
        copy.budget = session.budget;
        copy.campaign_type = session.campaign_type;
        copy.creative_cta = session.creative_cta;
        copy.creative_description = session.creative_description;
        copy.creative_destination = session.creative_destination;
        copy.creative_displayed_link = session.creative_displayed_link;
        copy.creative_file = session.creative_file;
        copy.creative_link = session.creative_link;
        copy.creative_sender = session.creative_sender;
        copy.creative_text = session.creative_text;
        copy.creative_title = session.creative_title;
        copy.creative_type = session.creative_type.toLowerCase();
        copy.date_entree = new Date();
        copy.end_date = session.end_date;
        copy.gender = session.gender;
        copy.name = session.name;
        copy.objectif = session.objectif;
        copy.region = session.region;
        copy.remarque = session.remarque;
        copy.start_date = new Date();

        setGlobalState(copy);
        setLoading(false);
      } else {
        let copy = { ...globalState };
        copy.name = query.client;
        copy.reference = query.ref;
        setGlobalState(copy);
        setLoading(false);
      }

      (async () => {
        await axios
          .get(`/api/airtableFetch?client=${query.client}&ref=${query.ref}`)
          .then((res) => {
            console.log(res);
            setAirtableInfo(res.data);

            return res;
          })
          .catch((e) => {
            console.log(e);
            return e;
          });
      })();
    }
  }, [location.search]);

  useEffect(() => {
    setLoading(true);
    let query = parseQuery(location.search);
    (async () => {
      await axios
        .get(`/api/airtableFetch?client=${query.client}&ref=${query.ref}`)
        .then((res) => {
          console.log(res);
          setAirtableInfo(res.data);
          setLoading(false);
          return res;
        })
        .catch((e) => {
          console.log(e);
          return e;
        });
    })();
  }, [step == 0]);

  // console.log(globalState)
  const globalSubmit = (e) => {
    e.preventDefault();
    let sharableLink = "";
    setLoading(true);
    console.log(e.target.id);
    ////////

    (async () => {
      let adName = "";

      let date = new Date().toLocaleString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      if (
        globalState.campaign_type === "facebook" ||
        globalState.campaign_type === "instagram"
      ) {
        if (globalState.adName === "") {
          if (globalState.objectif === "Story") {
            if (globalState.campaign_type === "instagram") {
              adName = `${date} - ${globalState.campaign_type} - ${globalState.objectif} | ${globalState.gender} ${globalState.age_min}-${globalState.age_max}`;
            } else {
              adName = `${date} - ${globalState.campaign_type}-Instagram - ${globalState.objectif} | ${globalState.gender} ${globalState.age_min}-${globalState.age_max}`;
            }
          } else {
            adName = `${date} - ${globalState.campaign_type} | ${globalState.gender} ${globalState.age_min}-${globalState.age_max}`;
          }
        } else {
          adName = `${globalState.adName} - ${globalState.campaign_type} | ${globalState.gender} ${globalState.age_min}-${globalState.age_max} - ${date}`;
        }
      } else {
        adName = `${globalState.adName} - ${globalState.campaign_type} | ${globalState.gender} ${globalState.age_min}-${globalState.age_max} - ${date}`;
      }
      if (globalState.creative_file !== null) {
        var config = {
          method: "post",
          url: "https://content.dropboxapi.com/2/files/upload",
          headers: {
            Authorization:
              "Bearer wLm2AxTUsyAAAAAAAAAAAeRq6kWOcC7jQmfhZDXBOL2VoWoBzqy6IrRYa88W1_n7",
            "Dropbox-API-Arg": `{"path": "/Campai/${globalState.name}_${
              globalState.reference
            }_${
              globalState.campaign_type
            }_${Date.now()}_${globalState.creative_file.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}"}`,
            "Content-Type": "application/octet-stream",
          },
          data: globalState.creative_file,
        };

        let upload = await axios(config)
          .then((res) => {
            console.log(res.data);
            return res.data.path_lower;
          })
          .catch(function (error) {
            console.log(error);
          });

        console.log(upload);

        sharableLink = await axios({
          method: "post",
          url:
            "https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings",
          headers: {
            Authorization:
              "Bearer wLm2AxTUsyAAAAAAAAAAAeRq6kWOcC7jQmfhZDXBOL2VoWoBzqy6IrRYa88W1_n7",
            "Content-Type": "application/json ;charset=ISO-8859-1",
          },
          data: JSON.stringify({
            path: upload,
            settings: {
              requested_visibility: "public",
              audience: "public",
              access: "viewer",
            },
          }),
        })
          .then(function (response) {
            console.log(response);
            // data.append("creativeFile", response.data);
            return response.data.url;
          })
          .catch(function (error) {
            console.log(error);
          });

        console.log("sharableLink", sharableLink);
        setGlobalState({ ...globalState, creative_file: sharableLink });

        // data.append("creativeFile", sharableLink);
      }

      ///

      //// update airtable
      let data = {
        budget: globalState.budget,
        campaign_type: globalState.campaign_type,
        objectif: globalState.objectif,
        nom_client_administratif: airtableInfo.nom_client_administratif,
        adName: adName,
        nom_de_la_campagne: airtableInfo.nom_de_la_campagne,
        sexe: globalState.gender,
        age_min: globalState.age_min,
        age_max: globalState.age_max,
        region: globalState.region,
        remarque: globalState.remarque,
        start_date: globalState.start_date,
        marque: airtableInfo.marque,
        end_date: globalState.end_date,
        reference: globalState.reference,
        client: globalState.name,
        creative_text: globalState.creative_text,
        creative_type: globalState.creative_type,
        creative_link: globalState.creative_link,
        creative_destination: globalState.creative_destination,
        creative_file: sharableLink,
        creative_cta: globalState.creative_cta,
        creative_title: globalState.creative_title,
        creative_sender: globalState.creative_sender,
        creative_description: globalState.creative_description,
        creative_displayed_link: globalState.creative_displayed_link,
        campaign_id: airtableInfo.campaignId,
        pour_le_compte_de: airtableInfo.pour_le_compte_de,
        budget_restant: budgetRestant,
      };

      console.log(data);

      let airtableCreate = await axios({
        method: "post",
        url: "/api/airtableCreate",
        data: data,
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        console.log(" created and updated");

        axios({
          method: "post",
          url: "/api/gmailapi",
          data: {
            nom_client_administratif: airtableInfo.nom_client_administratif,
            nom_de_la_campagne: airtableInfo.nom_de_la_campagne,
            budget: globalState.budget,
            campaign_type: globalState.campaign_type,
            objectif: globalState.objectif,
            sexe: globalState.gender,
            age_min: globalState.age_min,
            age_max: globalState.age_max,
            region: globalState.region,
            reference: globalState.reference,
            creative_text: globalState.creative_text,
            creative_link: globalState.creative_link,
            creative_destination: globalState.creative_destination,
            creative_file: sharableLink,
            creative_cta: globalState.creative_cta,
            creative_title: globalState.creative_title,
            creative_sender: globalState.creative_sender,
            creative_description: globalState.creative_description,
            creative_displayed_link: globalState.creative_displayed_link,
            budget_restant: budgetRestant,
            lien_de_la_campagne: airtableInfo.lien_de_la_campagne,
            email_to: airtableInfo.email_to,
            email_cc: airtableInfo.email_cc,
            sujet_du_mail: airtableInfo.sujet_du_mail,
            pour_le_compte_de: airtableInfo.pour_le_compte_de,
          },
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => {
            console.log(" Email sent");
            setAlert(
              <Alert severity="success">
                votre commande a bien été enregistrée
              </Alert>
            );
            setTimeout(() => {
              setAlert(null);
            }, 8000);

            sessionStorage.setItem(
              `Campaign_${globalState.reference}`,
              JSON.stringify(globalState)
            );

            setLoading(false);

            // setGlobalState(object);
            setStep(0);

            return res;
          })
          .catch((error) => {
            console.log(" Email not sent", error);
            setAlert(
              <Alert severity="error">
                votre commande n'a pas pu aboutir veuillez réessayer
                ultérieurement
              </Alert>
            );
            setTimeout(() => {
              setAlert(null);
            }, 8000);

            // console.log("ca na pas");
            return error;
          });
        // alert("created and updated");

        return res;
      });

      // axios({
      //   method: "post",
      //   url: "https://hooks.zapier.com/hooks/catch/1125328/o8gdhj6/",
      //   data: data,
      //   header: {
      //     Accept: "application/json",
      //     "Content-Type": "multipart/form-data",
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // })
      //   .then((res) => {
      //     console.log("zapier");
      //     setLoading(false);
      //     setGlobalState(object);
      //     setStep(0);
      //     return res;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     return e;
      //   });
    })();
  };

  return airtableInfo !== null ? (
    <Layout>
      <section className="contact-page">
        <article className={classes.root}>
          {alert !== null ? alert : null}
          <form id="main-form" onSubmit={globalSubmit}>
            <div>
              {step === 0 && (
                <div>
                  <div className={classes.sectionTitle}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="h5"
                    >
                      Budget et objectif
                    </Typography>
                  </div>
                  <div
                    className={classes.formGroup}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridGap: "10px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Nom du client"
                      name="name"
                      id="name"
                      type="text"
                      value={globalState.name}
                      onChange={handelChange}
                      placeholder="Nom du client"
                      required
                      form="main-form"
                    />

                    <TextField
                      variant="outlined"
                      fullWidth
                      label="reference"
                      name="reference"
                      id="reference"
                      type="text"
                      value={globalState.reference}
                      onChange={handelChange}
                      placeholder="reference"
                      required
                      form="main-form"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      label="Type de campagne"
                      id="campaign_type"
                      name="campaign_type"
                      required
                      value={globalState.campaign_type}
                      onChange={(event) => {
                        //handelChange(event);

                        let copy = { ...globalState };
                        copy.campaign_type = event.target.value;
                        copy.objectif = "";
                        copy.creative_type = "";
                        copy.creative_link = "";
                        copy.creative_destination = "";
                        copy.creative_text = "";
                        copy.creative_cta = "";
                        copy.creative_title = "";

                        // substractBudgetRestant(
                        //   event.target.value,
                        //   airtableInfo.budget_restant[event.target.value],
                        //   globalState.budget
                        // );

                        for (let i = 0; i < campaignObjectifData.length; i++) {
                          if (
                            campaignObjectifData[i].network ===
                            copy.campaign_type
                          )
                            setIndexObjectif(campaignObjectifData[i].id);
                        }

                        setGlobalState(copy);
                      }}
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="facebook">facebook</MenuItem>
                      <MenuItem value="instagram">instagram</MenuItem>
                      <MenuItem value="display">display</MenuItem>
                      <MenuItem value="youtube">youtube</MenuItem>
                      <MenuItem value="search">search</MenuItem>
                      <MenuItem value="snapchat">snapchat</MenuItem>
                      <MenuItem value="linkedin">linkedin</MenuItem>
                    </TextField>
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      name="objectif"
                      label="Objective"
                      id="objectif-select-label"
                      disabled={globalState.campaign_type === ""}
                      select
                      fullWidth
                      variant="outlined"
                      form="main-form"
                      required
                      value={globalState.objectif}
                      onChange={(e) => {
                        if (
                          globalState.objectif == "Impression" ||
                          globalState.objectif == "Interaction" ||
                          globalState.objectif == "Vue Video"
                        ) {
                          setGlobalState({
                            ...globalState,
                            creative_destination: "",
                            creative_text: "",
                            creative_cta: "",
                            creative_title: "",
                            objectif: e.target.value,
                          });
                        } else {
                          setGlobalState({
                            ...globalState,
                            creative_type: "",
                            creative_link: "",
                            creative_destination: "",
                            creative_text: "",
                            creative_cta: "",
                            creative_title: "",
                            objectif: e.target.value,
                          });
                        }
                      }}
                    >
                      {campaignObjectifData &&
                        campaignObjectifData[indexObjectif].objective.map(
                          (item, key) => {
                            return (
                              <MenuItem key={key} value={item}>
                                {item}
                              </MenuItem>
                            );
                          }
                        )}
                    </TextField>
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      type="number"
                      name="budget"
                      label="Budget"
                      fullWidth
                      variant="outlined"
                      placeholder="Budget"
                      margin="normal"
                      form="main-form"
                      value={globalState.budget}
                      disabled={globalState.campaign_type === ""}
                      onChange={handelChange}
                      required
                    />
                  </div>
                </div>
              )}

              {step === 1 && (
                <div>
                  <div className={classes.sectionTitle}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="h5"
                    >
                      Dates et Ciblages
                    </Typography>
                  </div>
                  <div
                    className={classes.formGroup}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridGap: "10px",
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        id="start_date"
                        allowKeyboardControl
                        minDate={new Date()}
                        name="start_date"
                        variant="outlined"
                        format="dd/MM/yyyy"
                        label="Date de début"
                        fullWidth
                        required
                        form="main-form"
                        value={globalState.start_date}
                        onChange={(day) => {
                          setGlobalState({ ...globalState, start_date: day });
                        }}
                      />
                      <DateTimePicker
                        id="end_date"
                        name="end_date"
                        allowKeyboardControl
                        minDate={new Date().getTime() + 1 * 24 * 60 * 60 * 1000}
                        variant="outlined"
                        format="dd/MM/yyyy"
                        label="Date de fin"
                        fullWidth
                        required
                        form="main-form"
                        value={
                          globalState.end_date === null
                            ? setGlobalState({
                                ...globalState,
                                end_date: new Date(
                                  new Date().setHours(23, 59, 59) +
                                    2 * 24 * 60 * 60 * 1000
                                ),
                              })
                            : globalState.end_date
                        }
                        onChange={(day) => {
                          setGlobalState({
                            ...globalState,
                            end_date: new Date(day),
                          });
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <Autocomplete
                      multiple
                      margin="normal"
                      debug
                      id="regions"
                      filterSelectedOptions
                      options={regions}
                      getOptionLabel={(option) => option.title}
                      value={globalState.region}
                      onChange={(event, newValue) => {
                        setGlobalState({ ...globalState, region: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Regions"
                          placeholder="Ajouter willaya"
                          required={
                            globalState.region.length === 0 ? true : false
                          }
                        />
                      )}
                    />
                  </div>

                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      className={classes.formGroup}
                      label="Créer une audience"
                      {...a11yProps(0)}
                    />
                    {/* <Tab
                      label="Utiliser l’audience enregistrée"
                      {...a11yProps(1)}
                    /> */}
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className={classes.formGroup}>
                      <TextField
                        label="Sexe"
                        margin="normal"
                        select
                        fullWidth
                        id="gender"
                        name="gender"
                        required
                        variant="outlined"
                        form="main-form"
                        value={globalState.gender}
                        onChange={handelChange}
                      >
                        <MenuItem value="" disabled></MenuItem>
                        <MenuItem value="Homme">Homme</MenuItem>

                        <MenuItem value="Femme">Femme</MenuItem>

                        <MenuItem value="Homme/Femme">Homme/Femme</MenuItem>
                      </TextField>
                    </div>
                    <div
                      className={classes.formGroup}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: "10px",
                      }}
                    >
                      <Autocomplete
                        id="age_min"
                        name="age_min"
                        fullWidth
                        required
                        margin="normal"
                        form="main-form"
                        options={ageFacebookInstagram}
                        getOptionDisabled={(option) =>
                          option > globalState.age_max
                        }
                        value={globalState.age_min}
                        onChange={(event, newValue) => {
                          setGlobalState({
                            ...globalState,
                            age_min: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Age Minimal"
                            variant="outlined"
                            required
                          />
                        )}
                      />

                      <Autocomplete
                        id="age_max"
                        name="age_max"
                        fullWidth
                        required
                        margin="normal"
                        form="main-form"
                        getOptionDisabled={(option) =>
                          option < globalState.age_min
                        }
                        options={ageFacebookInstagram}
                        value={globalState.age_max}
                        onChange={(event, newValue) => {
                          setGlobalState({
                            ...globalState,
                            age_max: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Age Maximal"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </TabPanel>
                  {/* <TabPanel value={value} index={1}>
                    Item Two
                  </TabPanel> */}
                </div>
              )}

              {step === 2 && (
                <div>
                  <div className={classes.sectionTitle}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="h5"
                    >
                      Annonces
                    </Typography>
                  </div>
                  <div className={classes.formGroup}>
                    {globalState.objectif !== "" ? (
                      <TextField
                        required
                        variant="outlined"
                        fullWidth
                        name="creative_type"
                        value={globalState.creative_type}
                        label="Type d'annonce"
                        select
                        onChange={handelChange}
                      >
                        {campaignObjectifData[indexObjectif].creative[0][
                          globalState.objectif
                        ].map((element, index) => {
                          return (
                            <MenuItem
                              selected={index === 0}
                              key={index}
                              value={element}
                            >
                              {element}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    ) : null}
                    {globalState.creative_type === "Post" ? (
                      <TextField
                        name="creative_link"
                        fullWidth
                        required
                        variant="outlined"
                        value={globalState.creative_link}
                        margin="normal"
                        label="Lien de la publication"
                        onChange={handelChange}
                      />
                    ) : null}

                    {globalState.creative_type === "DarkPost" ? (
                      <>
                        {globalState.objectif === "Traffic" ? (
                          <>
                            <TextField
                              name="creative_title"
                              fullWidth
                              multiline
                              variant="outlined"
                              value={globalState.creative_title}
                              margin="normal"
                              label="Titre de l'annonce"
                              onChange={handelChange}
                            />
                            <TextField
                              name="creative_description"
                              fullWidth
                              multiline
                              variant="outlined"
                              value={globalState.creative_description}
                              margin="normal"
                              label="Description"
                              onChange={handelChange}
                            />
                            <TextField
                              name="creative_displayed_link"
                              fullWidth
                              multiline
                              variant="outlined"
                              value={globalState.creative_displayed_link}
                              margin="normal"
                              label="Lien d’affichage"
                              onChange={handelChange}
                            />
                          </>
                        ) : null}
                        <TextField
                          name="creative_text"
                          fullWidth
                          rows={10}
                          multiline
                          variant="outlined"
                          value={globalState.creative_text}
                          required
                          margin="normal"
                          label="Texte de l'annonce"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_destination"
                          fullWidth
                          variant="outlined"
                          value={globalState.creative_destination}
                          required={
                            globalState.objectif === "Traffic" ||
                            globalState.creative_cta !== ""
                          }
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_cta"
                          fullWidth
                          variant="outlined"
                          select
                          value={globalState.creative_cta}
                          required={globalState.objectif === "Traffic"}
                          margin="normal"
                          label="Call to Action"
                          onChange={handelChange}
                        >
                          <MenuItem value="" disabled></MenuItem>
                          {cta.map((element, index) => {
                            return (
                              <MenuItem key={index} value={element.value}>
                                {element.title}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}
                          margin="normal"
                          label="Image ou video de la publication"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}
                    {globalState.creative_type === "Recrutement De Fans" ? (
                      <>
                        <TextField
                          name="creative_text"
                          fullWidth
                          rows={10}
                          multiline
                          variant="outlined"
                          value={globalState.creative_text}
                          required
                          margin="normal"
                          label="Texte de l'annonce"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}
                          margin="normal"
                          label="Image ou video de la l'annonce"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}

                    {globalState.creative_type === "Story" ? (
                      <>
                        <TextField
                          name="creative_destination"
                          fullWidth
                          variant="outlined"
                          value={globalState.creative_destination}
                          required={
                            globalState.objectif === "Traffic" ||
                            globalState.creative_cta !== ""
                          }
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />
                        <TextField
                          name="creative_cta"
                          fullWidth
                          variant="outlined"
                          select
                          value={globalState.creative_cta}
                          required={globalState.creative_destination !== ""}
                          margin="normal"
                          label="Call to Action"
                          onChange={handelChange}
                        >
                          <MenuItem value="" disabled></MenuItem>
                          {cta.map((element, index) => {
                            return (
                              <MenuItem key={index} value={element.value}>
                                {element.title}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}

                          margin="normal"
                          label="Image ou video de la l'annonce"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}

                    {globalState.creative_type === "Geofiltre" ? (
                      <>
                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}
                          margin="normal"
                          label="Image du Filtre"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}
                    {globalState.creative_type === "Bannier" ? (
                      <>
                        <TextField
                          name="creative_destination"
                          fullWidth
                          value={globalState.creative_destination}
                          required
                          variant="outlined"
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}
                          margin="normal"
                          label="Image ou video de la publication"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}
                    {globalState.creative_type === "Annonce textuelle" ? (
                      <>
                        <TextField
                          name="creative_destination"
                          fullWidth
                          variant="outlined"
                          value={globalState.creative_destination}
                          required
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_file"
                          fullWidth
                          type="file"
                          variant="filled"
                          required
                          // value={globalState.creative_file}
                          margin="normal"
                          label="Annonces, Mots clés et Tags"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}
                    {globalState.creative_type === "Inmail" ? (
                      <>
                        <TextField
                          name="creative_sender"
                          fullWidth
                          multiline
                          variant="outlined"
                          value={globalState.creative_sender}
                          required
                          margin="normal"
                          label="Nom D'Expediteur"
                          onChange={handelChange}
                        />
                        <TextField
                          name="creative_title"
                          fullWidth
                          multiline
                          variant="outlined"
                          value={globalState.creative_title}
                          required
                          margin="normal"
                          label="Objet du Mail"
                          onChange={handelChange}
                        />
                        <TextField
                          name="creative_text"
                          fullWidth
                          multiline
                          rows={10}
                          variant="outlined"
                          value={globalState.creative_text}
                          required
                          margin="normal"
                          label="Corps Du Message"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_destination"
                          fullWidth
                          variant="outlined"
                          value={globalState.creative_destination}
                          required
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_cta"
                          fullWidth
                          variant="outlined"
                          select
                          value={globalState.creative_cta}
                          required
                          margin="normal"
                          label="Call to Action"
                          onChange={handelChange}
                        >
                          <MenuItem value="" disabled></MenuItem>
                          {cta.map((element, index) => {
                            return (
                              <MenuItem key={index} value={element.value}>
                                {element.title}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </>
                    ) : null}
                    {globalState.creative_type === "Video" ? (
                      <>
                        <TextField
                          name="creative_link"
                          fullWidth
                          variant="outlined"
                          required
                          value={globalState.creative_link}
                          margin="normal"
                          label="Lien de la publication"
                          onChange={handelChange}
                        />

                        <TextField
                          name="creative_destination"
                          fullWidth
                          variant="outlined"
                          value={globalState.creative_destination}
                          required
                          margin="normal"
                          label="Url de destination"
                          onChange={handelChange}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              )}

              {step === 3 ? (
                <>
                  <div className={classes.sectionTitle}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="h5"
                    >
                      Remarque
                    </Typography>
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      name="adName"
                      fullWidth
                      variant="outlined"
                      value={globalState.adName}
                      margin="normal"
                      label="Nom de la publicité"
                      onChange={handelChange}
                    />
                    <TextField
                      name="remarque"
                      fullWidth
                      rows={10}
                      multiline
                      variant="outlined"
                      value={globalState.remarque}
                      margin="normal"
                      label="Remarque"
                      onChange={handelChange}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </form>
          <div className={classes.ctaContainer}>
            {step >= 1 && step < 5 ? (
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Précedent{" "}
              </Button>
            ) : null}

            {step >= 0 && step < 3 ? (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={
                  loading ||
                  globalState.budget - airtableInfo.budget_restant.total > 0
                }
                onClick={(e) => {
                  {
                    document.forms[0].reportValidity() && setStep(step + 1);
                  }
                }}
              >
                Suivant{" "}
              </Button>
            ) : null}

            {step === 3 ? (
              <>
                <Button
                  variant="contained"
                  disabled={loading}
                  className={classes.button}
                  type="submit"
                  form="main-form"
                  color="primary"
                  id="envoyer"
                  // style={{
                  //   padding: "1rem",
                  //   fontSize: "1rem",
                  //   borderRadius: "0px",
                  //   marginTop: "auto",
                  // }}
                >
                  {loading ? <CircularProgress /> : "Envoyer"}
                </Button>
                {/* <Button
                  variant="contained"
                  disabled={loading}
                  className={classes.button}
                  type="submit"
                  form="main-form"
                  color="secondary"
                  id="dupliquer"
                  // style={{
                  //   padding: "1rem",
                  //   fontSize: "1rem",
                  //   borderRadius: "0px",
                  //   marginTop: "auto",
                  // }}
                >
                  {loading ? <CircularProgress /> : "Envoyer et dupliquer"}
                </Button> */}
              </>
            ) : null}
          </div>
        </article>
      </section>
    </Layout>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#222",
        // border: "3px solid green",
        // padding: "10px",
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default Campagne;

// export const query = graphql`
//   {
//     allAirtable(filter: { table: { eq: "Marques" } }) {
//       nodes {
//         data {
//           marques
//           Pour_le_compte_de
//           Nom_sur_ZOHO_BOOK
//           Possibilit__d_appelation
//         }
//       }
//     }
//   }
// `;
